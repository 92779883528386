import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Container from '../__Container';
import Tabs from '../__Tabs';

import Banner from '../__Banner';
import Slider from './_Slider';
import BannerImg from 'imgs/1d1d/pc/banner.news.png';

const Wrapper = styled.div`
  background: rgb(250, 250, 250);
  padding: 0.36rem 0.46rem;
  .sections {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
  }
`;
export default function Videos() {
  return (
    <Container>
      <Helmet>
        <script src="https://cdn.bootcss.com/jquery/2.1.2/jquery.min.js"></script>
      </Helmet>
      <Banner h1="新闻中心" h2s={['news center']} banner={BannerImg} />
      <Wrapper>
        <div className="sections">
          <Tabs selected={2} />
        </div>
        <Slider />
      </Wrapper>
    </Container>
  );
}
