import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import videos from 'RootCommon/_videoData';

global.$ = global.jQuery = require('jquery');
const Wrapper = styled.section`
  .playModal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      font-size: 0.32rem;
      height: 0.72rem;
      line-height: 0.72rem;
      color: #ffffff;
      background: #424a5b;
      text-indent: 0.32rem;
      width: 90%;
    }
    > .video {
      width: 90%;
      height: 0;
      padding-bottom: 75%;
      background: #000;
      position: relative;
      > video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .closeBtn {
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      background: url('http://www.100tal.com/skin/img/close1.png') no-repeat;
      background-size: 100% 100%;
      right: 0.4rem;
      top: 3rem;
      position: absolute;
      z-index: 99;
      cursor: pointer;
    }
  }
  #gla_box {
    margin: auto;
    position: relative;
    .gla_inbox {
      overflow: hidden;
      position: relative;
      margin-top: 0.2rem;
      p {
        text-indent: 1em;
        font-size: 0.7rem;
        width: 100%;
        color: #ffffff;
        line-height: 1.5;
        background: #000;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    p {
      font-size: 0.18rem;
      color: #636363;
      height: 0.6rem;
      line-height: 0.6rem;
      border-bottom: 1px solid #f3f3f3;
    }
    .box-con {
      position: relative;
      .ico {
        position: absolute;
        cursor: pointer;
        width: 0.8rem;
        height: 0.8rem;
        background: url('http://www.100tal.com/skin/img/aniu.png') no-repeat center;
        background-size: 0.8rem;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      .boxbg {
        cursor: pointer;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.4);
      }
    }
    .roundabout-in-focus .box-con {
      p,
      .ico,
      .boxbg {
        display: block;
      }
    }
  }
`;
export default function Slider() {
  const [vUrl, setVUrl] = useState('');
  const videoEle = useRef(null);
  const handleClick = (vUrl) => {
    setVUrl(vUrl);
  };
  const handleCloseModal = () => {
    setVUrl('');
  };
  return (
    <Wrapper>
      {vUrl && (
        <div className="playModal">
          <div className="video">
            <video ref={videoEle} controls>
              <source src={vUrl} />
            </video>
          </div>
          {/* <p>{title}</p> */}

          <div className="closeBtn" onClick={handleCloseModal}></div>
        </div>
      )}
      <div id="gla_box">
        <ul>
          {videos.map((v, idx) => {
            return (
              <li key={v.title + idx}>
                <div className="gla_inbox">
                  <div className="box-con" onClick={handleClick.bind(null, v.video)}>
                    <div className="boxbg"></div>
                    <i className="ico" data-title={v.title}></i>
                    <img src={v.cover} />
                  </div>
                </div>
                <p>{v.title}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </Wrapper>
  );
}
