import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 0.46rem;
  > a {
    text-decoration: none;
    border-radius: 0.1rem;
    margin-right: 0.38rem;
    font-size: 0.28rem;
    color: #636363;
    background: #f3f3f3;
    width: 1.5rem;
    height: 0.69rem;
    line-height: 0.69rem;
    text-align: center;
    &.selected {
      color: #fff;
      background: #ff7c34;
      position: relative;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 0.48rem solid transparent;
        border-right: 0.48rem solid transparent;
        border-top: 0.24rem solid #ff7c34;
        position: absolute;
        bottom: -0.15rem;
        left: 0.28rem;
        display: block;
      }
    }
  }
`;
const data = [
  {
    title: '企业动态',
    link: '/news/'
  },
  {
    title: '点滴人物',
    link: '/people/'
  },
  {
    title: '视频专区',
    link: '/videos/'
  }
];
export default function Tabs({ selected = 0 }) {
  return (
    <Wrapper>
      {data.map((link, idx) => {
        return (
          <Link className={selected == idx ? 'selected' : ''} key={link.link} to={link.link}>
            {link.title}
          </Link>
        );
      })}
    </Wrapper>
  );
}
